<template>
  <div class="header">
    <div class="main">
      <div class="logo-box">
        <!-- <img :src="shopInit.shopPcLogo ? shopInit.shopPcLogo : ''" alt="" class="logo" :onerror="errImg" @click="getNav('/')"> -->
      </div>
      <div class="items">
        <div class="item" @click="getNav('/diamondSearch')">全球搜钻</div>
        <div class="item" @click="getNav('/ringSearch?type=求婚钻戒')">求婚钻戒</div>
        <div class="item" @click="getNav('/ringSearch?type=结婚对戒')">结婚对戒</div>
        <div class="item" @click="getNav('/certSearch')">证书查询</div>
        <div class="item" @click="getNav('/appointment')">预约到店</div>
      </div>
      <div class="tel">
        <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/Shop/Web/assets/tel.png" alt="" class="tel-img">
        <div class="tel-items">
          <div class="phone">{{shopInit.shopTelephone}}</div>
          <div class="text">免费服务热线</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hd',
    data() {
      return {
        errImg: 'this.src="'+require('../../assets/logo.png')+'"'
      }
    },
    props: {
      disable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        console.log('head', shop)
        return shop
      }
    },
    watch: {},
    methods: {
      getNav(path){
        if (this.disable) return
        this.$router.push({ path: path })
      },
      getNavs(path) {
        if (this.disable) return
        window.location.href = path
      },
    }
  }
</script>

<style scoped lang="scss">
  .header {
    background: #FFFFFF;
    box-shadow: 0 2px 4px 0 #F2F2F2;
    width: 100%;

    .main{
      width: 1200px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .logo-box{
      width: 134px;
      height: 42px;
      text-align: center;
    }
    .logo{
      width: 134px;
      height: 42px;
      cursor: pointer;
      // border-radius: 10px;
    }

    .items{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 70px;

      .item{
        width: 60px;
        margin-right: 55px;
        font-size: 15px;
        padding: 3px 0;
        cursor: pointer;
        white-space:nowrap;

        &:hover{
          border-bottom: 2px solid #F5B5A9;
          font-weight: 600;
        }

        &.active{
          border-bottom: 2px solid #F5B5A9;
          font-weight: 500;
        }
      }
    }

    .tel{
      margin-left: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .tel-img{
        width: 42px;
        height: 42px;
      }

      .tel-items{
        margin-left: 12px;
        .phone{
          font-size: 15px;
        }

        .text{
          font-size: 12px;
        }
      }
    }
  }
</style>
