import Vue from 'vue'
import VueRouter from 'vue-router'

//meta: { keepAlive: false } 是否缓存页面、搜索、列表、
// 路由快捷键
// 钻石 diamond 证书 cert 托 ring 预约 appointment 异常页 warning
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index/index.vue'),
    meta: {
      title: '云店铺PC端',
      keepAlive: true
    }
  },
  {
    path: '/diamondSearch',
    name: 'diamondSearch',
    component: () => import('../views/diamond/search.vue'),
    meta: {
      title: '全球搜钻',
      keepAlive: true
    }
  },
  {
    path: '/diamondDetails',
    name: 'diamondDetails',
    component: () => import('../views/diamond/details.vue'),
    meta: {
      title: '钻石详情',
      keepAlive: false
    }
  },
  {
    path: '/diamondRules',
    name: 'diamondRules',
    component: () => import('../views/diamond/rules.vue'),
    meta: {
      title: '钻石4C',
      keepAlive: false
    }
  },
  {
    path: '/ringSearch',
    name: 'ringSearch',
    component: () => import('../views/ring/search.vue'),
    meta: {
      title: '钻饰',
      keepAlive: true
    }
  },
  {
    path: '/ringDetails',
    name: 'ringDetails',
    component: () => import('../views/ring/details.vue'),
    meta: {
      title: '钻饰详情',
      keepAlive: false
    }
  },
  {
    path: '/certSearch',
    name: 'certSearch',
    component: () => import('../views/cert/search.vue'),
    meta: {
      title: '证书查询',
      keepAlive: false
    }
  },
  {
    path: '/certDetails',
    name: 'certDetails',
    component: () => import('../views/cert/details.vue'),
    meta: {
      title: '证书查询',
      keepAlive: false
    }
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: () => import('../views/appointment/appointment.vue'),
    meta: {
      title: '预约到店',
      keepAlive: false
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/appointment/success.vue'),
    meta: {
      title: '预约成功',
      keepAlive: false
    }
  },
  {
    path: '/service',
    name: 'service',
    component: () => import('../views/service/index.vue'),
    meta: {
      title: '帮助中心',
      keepAlive: false
    }
  },
  {
    path: '/upgrade',
    name: 'upgrade',
    component: () => import('../views/warning/upgrade.vue'),
    meta: {
      title: '请升级',
      keepAlive: false
    }
  },
  {
    path: '/nothing',
    name: 'nothing',
    component: () => import('../views/warning/nothing.vue'),
    meta: {
      title: '商品未上架',
      keepAlive: false
    }
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('../views/warning/error.vue'),
    meta: {
      title: '错误',
      keepAlive: false
    }
  },
  {
    path: '/maturity',
    name: 'maturity',
    component: () => import('../views/warning/maturity.vue'),
    meta: {
      title: '店铺到期',
      keepAlive: false
    }
  },
  {
    path: '/regret',
    name: 'regret',
    component: () => import('../views/warning/regret.vue'),
    meta: {
      title: '商品被购买',
      keepAlive: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//以下代码解决路由地址重复点击的报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  console.log(to)
  if (to.meta.title) {
    document.title = '云店铺PC端'
    // document.title = to.meta.title
  }
  next()
})

// 新页面回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})

export default router
