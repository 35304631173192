<template>
  <div class="footer">
    <div class="main">
      <div class="list">
        <div class="items" v-for="(item, index) in footList" :key="index">
          <div class="title">{{item.title}}</div>
          <div class="item" v-for="(citem, cindex) in item.list" :key="cindex">
            <div class="text" @click="godel(citem.url)">{{citem.text}}</div>
          </div>
        </div>
        <div class="items img">
<!--          <div class="title">扫码识别小程序</div>-->
          <div class="item">
            <img :src="shopInit.shopWeChatAppQRCode ? shopInit.shopWeChatAppQRCode : ''" alt="" :onerror="errImg">
<!--            <img :src="shopInit.shopWeChatAppQRCode"  alt="">-->
            <div class="icon-t">小程序商城</div>
          </div>
        </div>
      </div>
      <div class="record">
        <div class="address">门店地址：{{shopInit.shopProvince}}{{shopInit.shopCity}}{{shopInit.shopArea}}{{shopInit.shopAddress}} 服务热线：{{shopInit.shopTelephone}}</div>
        <div class="code">Copyright © 2021 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2021037216号-4</a></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'foot',
    data() {
      return {
        errImg: 'this.src="'+require('../../assets/qrcode.png')+'"',
        footList:[
          {
            title: '关于我们',
            list: [
              // {text: '品牌介绍', url: '/service?t=brand'},
              {text: '市场合作', url: '/service?t=market'},
            ]
          },
          {
            title: '购物指南',
            list: [
              {text: '定金规则', url: '/service?t=depositRules'},
              {text: '定制时间', url: '/service?t=customTime'},
              {text: '配送方法及收费', url: '/service?t=deliveryMethod'},
            ]
          },
          {
            title: '支付与物流',
            list: [
              // {text: '在线支付', url: '/service?t=onlinePayment'},
              {text: '银行转账', url: '/service?t=bankTransfer'},
              {text: '支付常见问题', url: '/service?t=paymentProblems'},
            ]
          },
          {
            title: '售后服务',
            list: [
              // {text: '15天退换货', url: '/service?t=daysReturn'},
              {text: '售后服务', url: '/service?t=afterSalesService'},
              {text: '购物协议', url: '/service?t=shoppingAgreement'},
              {text: '质量承诺', url: '/service?t=qualityPromise'},
            ]
          },
        ]
      }
    },
    props: {
      disable: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      shopInit: function () {
        let shop = this.$store.state.shop
        return shop
      },
    },
    methods: {
      godel(path) {
        if (this.disable) return
        // window.location = path
        this.$router.push({
          path: path
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .footer {
    width: 100%;
    background: #FBECE0;

    .main{
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0px 60px;
    }

    .list{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      font-size: 14px;

      .items{
        margin-right: 90px;

        .title{
          color: #000;
          font-size: 15px;
          font-weight: 600;
          margin-bottom: 25px;
        }

        .item{
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 12px;
          cursor: pointer;

          .text:hover{
            color: #FF8178;
            transform: scale(1.0, 1.0);
          }
        }

        &.img{
          margin-left: auto;
          margin-right: 0;
          img{
            width: 107px;
            height: 107px;
            margin-bottom: 12px;
          }

          .icon-t{
            font-size: 13px;
            color: #333333;
            text-align: center;
          }
        }
      }
    }

    .record{
      font-size: 13px;
      color: #666666;
      text-align: center;
      margin-top: 57px;

      .address{
        margin-bottom: 15px;
      }

      .code{
        a{
          color: #666666;
          text-decoration: none;
        }
      }
    }
  }
</style>
